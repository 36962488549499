import ApiResource from '@/core/http/ApiResource';
import { $gettext } from '@/gettext';
import { PartnerWhitelabeling } from '@/modules/partner/Partner';
import { LawFirm } from '@/modules/law_firm/LawFirm';

export type CustomerLawFirmMode = 'PARTNER' | 'CUSTOMER' | 'CUSTOMER_FIRST' | 'NONE';

export const customerLawFirmModeNames: Record<CustomerLawFirmMode, string> = {
	'PARTNER': $gettext('Återförsäljarens'),
	'CUSTOMER': $gettext('Kundens'),
	'CUSTOMER_FIRST': $gettext('Kunden hanterar själv, kan ta hjälp'),
	'NONE': $gettext('Ingen'),
};

interface CustomerAssociatedEntityStub {
	id: number;
	name: string | undefined;
}

export interface CustomerInvoiceAddress {
	address: string | null;
	zipcode: string | null;
	city: string | null;
	email: string | null;
}

export interface CustomerStub {
	id: number;
	identity_number: string;
	name: string;
	url: string;
	contact_name: string;
	partner: CustomerAssociatedEntityStub;
	demo: boolean;
	created_time: string;
}

export interface Customer extends CustomerStub {
	email: string,
	phone: string,
	whistle_phone: string | null;
	law_firm_mode: CustomerLawFirmMode;
	invoice: CustomerInvoiceAddress,
	law_firms: CustomerLawFirm[];
	languages: string[];
}

export interface FullCustomer extends Customer {
	whitelabeling?: PartnerWhitelabeling;
}

export interface MeCustomer extends CustomerStub {
	whitelabeling?: PartnerWhitelabeling;
	law_firm_mode: CustomerLawFirmMode;
	law_firm_has_view_all_tickets: boolean;
}

export interface CustomerLawFirm {
	law_firm: LawFirm;
	can_view_all_tickets: boolean;
}

class CustomerResource extends ApiResource<Customer> {

	constructor() {
		super('/api/customer');
	}

	getLawFirms(customerId: number, withLoader = this.withLoader): Promise<CustomerLawFirm[]> {
		return this.getApi(withLoader)
			.get(`${this.baseUrl}/${customerId}/law_firm`)
		;
	}

	putLogotype(customerId: number, data: File, withLoader = this.withLoader): Promise<Customer> {
		const formData = new FormData();
		formData.set('file', data);

		return this.getApi(withLoader)
			.put(`${this.baseUrl}/${customerId}/logotype`, formData)
		;
	}

	setLawFirm(customerId: number, customerLawFirm: CustomerLawFirm, withLoader = this.withLoader): Promise<Customer> {
		return this.getApi(withLoader)
			.put(`${this.baseUrl}/${customerId}/law_firm/${customerLawFirm.law_firm.id}`, {
				can_view_all_tickets: customerLawFirm.can_view_all_tickets,
			})
		;
	}

	removeLawFirm(customerId: number, lawFirmId: number, withLoader = this.withLoader): Promise<Customer> {
		return this.getApi(withLoader)
			.delete(`${this.baseUrl}/${customerId}/law_firm/${lawFirmId}`)
		;
	}

	setLanguage(customerId: number, languageId: string, withLoader = this.withLoader): Promise<Customer> {
		return this.getApi(withLoader)
			.put(`${this.baseUrl}/${customerId}/language/${languageId}`, {})
		;
	}

	removeLanguage(customerId: number, languageId: string, withLoader = this.withLoader): Promise<Customer> {
		return this.getApi(withLoader)
			.delete(`${this.baseUrl}/${customerId}/language/${languageId}`)
		;
	}
}

export const useCustomer = () => new CustomerResource();
