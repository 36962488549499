export function shortDate(date): string {
	if (typeof date !== 'string') {
		return '';
	}

	return new Date(date).toLocaleDateString('sv', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
}

export function dateTime(date): string {
	if (typeof date !== 'string') {
		return '';
	}

	return new Date(date).toLocaleDateString('sv', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
}