import ApiResource from '@/core/http/ApiResource';
import { ContentModel } from '@/core/languageContent';
import { UserType } from '@/modules/user/User';
import { $gettext } from '@/gettext';

export interface NewsContent extends ContentModel {
	headline: string;
	text: string;
}

export interface News {
	id: number;
	user_type: UserType[];
	content: NewsContent[];
	created_time: string;
}

export const newsUserTypes = {
	PARTNER: $gettext('Återförsäljare'),
	LAW_FIRM: $gettext('Externa partners'),
	CUSTOMER: $gettext('Kunder'),
};

export const useNews = () => new ApiResource<News>('/api/news');
