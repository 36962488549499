import ApiResource from '@/core/http/ApiResource';
import { CustomerStub } from '@/modules/customer/Customer';
import { User, UserUpdate } from '@/modules/user/User';

interface LawFirmBase {
	identity_number: string;
	name: string;
}

export interface LawFirm extends LawFirmBase{
	id: number;
	created_time: string;
}

export interface LawFirmCreate extends LawFirmBase {
	user: boolean | undefined;
	invite_user_email: string | undefined;
}

class LawFirmResource extends ApiResource<LawFirm> {
	getConnectedCustomers(lawFirmId: number, withLoader = this.withLoader): Promise<CustomerStub[]> {
		return this.getApi(withLoader)
			.get(`${this.baseUrl}/${lawFirmId}/customer`)
		;
	}

	getByUserInvite(hash: string, withLoader = this.withLoader): Promise<LawFirm> {
		return this.getApi(withLoader)
			.get(`${this.baseUrl}/${hash}/user_invite`)
		;
	}

	answerUserInvite(hash: string, data: Partial<UserUpdate>, withLoader = this.withLoader): Promise<User> {
		return this.getApi(withLoader)
			.post(`${this.baseUrl}/${hash}/user_invite`, data)
		;
	}
}

export const useLawFirm = () => new LawFirmResource('/api/law_firm');
