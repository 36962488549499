import { $gettext } from '@/gettext';
import ApiResource from '@/core/http/ApiResource';
import { LawFirm } from '@/modules/law_firm/LawFirm';

export type TicketStateEnum = 'UNREAD' | 'ONGOING' | 'DONE';
export type TicketAddedVia = 'FORM' | 'PHONE' | 'EMAIL' | 'MAIL';

export const addedViaNames: Record<TicketAddedVia, string> = {
	'FORM': $gettext('Webbformulär'),
	'PHONE': $gettext('Telefon'),
	'EMAIL': $gettext('E-post'),
	'MAIL': $gettext('Brev'),
};

interface TicketAssociatedEntityStub {
	id: number;
	name: string | undefined;
}

export interface TicketStub {
	id: number;
	state: TicketStateEnum;
	added_via: TicketAddedVia;
	customer: TicketAssociatedEntityStub;
	created_time: string;
	category: TicketAssociatedEntityStub | undefined;
	status: TicketAssociatedEntityStub | undefined;
	worker: TicketAssociatedEntityStub | undefined;
	partner: TicketAssociatedEntityStub | undefined;
}

export interface Ticket extends TicketStub {
	law_firm: LawFirm | null;
	reporter_name: string;
	what: string;
	when: string;
	where: string;
	description: string;
	merged: number[];
	merged_to?: number;
}

export interface TicketCreate extends Ticket {
	ticket_category: number | undefined;
}

export interface TicketAttachment {
	id: number;
	file_name: string,
	mime_type: string,
}

export interface TicketStatistics {
	unread: number;
	ongoing: number;
	done: number;
}

class TicketResource extends ApiResource<Ticket> {
	putStatus(ticketId: number, statusId: number | null): Promise<Ticket> {
		return this.getApi(true).put(`/api/ticket/${ticketId}/status`, {
			ticket_status: statusId,
		});
	}

	putState(ticketId: number, state: TicketStateEnum): Promise<Ticket> {
		return this.getApi(true).put(`/api/ticket/${ticketId}/state`, {
			state,
		});
	}

	putMerge(ticketId: number, destination: number): Promise<Ticket> {
		return this.getApi(true).put(`/api/ticket/${ticketId}/merge`, {
			destination,
		});
	}

	assignLawFirm(ticketId: number, lawFirmId: number | null): Promise<Ticket> {
		return this.getApi(true).put(`/api/ticket/${ticketId}/law_firm`, {
			law_firm: lawFirmId,
		});
	}

	assignWorker(ticketId: number, workerUserId: number | null): Promise<Ticket> {
		return this.getApi(true).put(`/api/ticket/${ticketId}/worker`, {
			worker: workerUserId,
		});
	}

	unAssignWorker(ticketId: number): Promise<Ticket> {
		return this.getApi(true).delete(`/api/ticket/${ticketId}/worker`);
	}

	getAttachments(ticketId): Promise<TicketAttachment[]> {
		return this.getApi(true).get(`/api/ticket/${ticketId}/attachment`);
	}

	getStatistics(): Promise<TicketStatistics> {
		return this.getApi(true).get('/api/ticket/statistics');
	}
}

export const useTicket = () => new TicketResource('/api/ticket');
